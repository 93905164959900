import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Label,
  FormGroup,
  Spinner,
  Input,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useForm, useFieldArray, Controller } from "react-hook-form"
import DataTable from "react-data-table-component"
import MetaTags from "react-meta-tags"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import toast, { Toaster } from "react-hot-toast"
import { Eye, EyeOff } from "lucide-react"

import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"

const AIConfig = () => {
  const [templatePrompts, setTemplatePrompts] = useState([])
  const [modal, setModal] = useState({ isOpen: false, mode: "create" })
  const [isLoading, setIsLoading] = useState(false)
  const [deleteConfig, setDeleteConfig] = useState({ confirm_alert: false })
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [aiSettings, setAISettings] = useState({ apiKey: "", model: "" })
  const [originalAISettings, setOriginalAISettings] = useState({
    apiKey: "",
    model: "",
  })
  const [selectedConfig, setSelectedConfig] = useState(null)
  const [showApiKey, setShowApiKey] = useState(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      key: "",
      title: "",
      prompts: [{ value: "" }],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "prompts",
  })

  const authUser = localStorage.getItem("authUser")
  const token = authUser ? JSON.parse(authUser)?.user?.token : undefined

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        toast.error(
          typeof text === "string" ? text : "An unexpected error occurred"
        )
        break
      default:
        break
    }
  }

  useEffect(() => {
    getTemplatePrompts()
    getAISettings()
  }, [page, rowsPerPage, searchQuery])

  useEffect(() => {
    if (selectedConfig && modal.isOpen) {
      reset({
        key: selectedConfig.key,
        title: selectedConfig.title,
        prompts: selectedConfig.prompts.map(prompt => ({ value: prompt })),
      })
    }
  }, [selectedConfig, reset, modal.isOpen])

  const getTemplatePrompts = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/templatePrompts/getAllTemplatePrompts?page=${page}&limit=${rowsPerPage}&search=${searchQuery}`,
        config
      )
      setTemplatePrompts(response.data.templatePrompts)
      setTotalCount(response.data.totalCount)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      notify(
        "error",
        err?.response?.data?.error || "Failed to fetch template prompt"
      )
    }
  }

  const getAISettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/templatePrompts/getAISettings`,
        config
      )
      setAISettings(response.data.aiSettings)
      setOriginalAISettings(response.data.aiSettings)
    } catch (err) {
      notify(
        "error",
        err?.response?.data?.error || "Failed to fetch AI settings"
      )
    }
  }

  const handleCloseModal = () => {
    setModal({ isOpen: false, mode: "create" })
    setSelectedConfig(null)
    reset({
      key: "",
      title: "",
      prompts: [{ value: "" }],
    })
  }

  const onSubmit = async data => {
    if (isLoading) return
    setIsLoading(true)

    const nonEmptyPrompts = data.prompts
      .map(prompt => prompt.value.trim())
      .filter(Boolean)

    if (nonEmptyPrompts.length === 0) {
      notify("error", "At least one prompt is required")
      setIsLoading(false)
      return
    }

    const submitData = {
      key: data.key.trim(),
      title: data.title,
      prompts: nonEmptyPrompts,
    }

    try {
      if (modal.mode === "create") {
        await axios.post(
          `${process.env.REACT_APP_PORT}/api/templatePrompts/createTemplatePrompt`,
          submitData,
          config
        )
        notify("success", "Template Prompt added successfully")
      } else {
        await axios.put(
          `${process.env.REACT_APP_PORT}/api/templatePrompts/updateTemplatePrompt/${selectedConfig._id}`,
          submitData,
          config
        )
        notify("success", "Template Prompt updated successfully")
      }
      handleCloseModal()
      await getTemplatePrompts()
    } catch (err) {
      notify(
        "error",
        err?.response?.data?.error || `Failed to ${modal.mode} template prompt`
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteConfig = async id => {
    try {
      setIsLoading(true)
      await axios.delete(
        `${process.env.REACT_APP_PORT}/api/templatePrompts/deleteTemplatePrompt/${id}`,
        config
      )
      await getTemplatePrompts()
      notify("success", "Template Prompt deleted successfully")
    } catch (err) {
      notify(
        "error",
        err?.response?.data?.error || "Failed to delete template prompt"
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleSaveAISettings = async () => {
    try {
      setIsLoading(true)
      await axios.put(
        `${process.env.REACT_APP_PORT}/api/templatePrompts/updateAISettings`,
        aiSettings,
        config
      )
      setOriginalAISettings(aiSettings)
      notify("success", "AI Settings updated successfully")
    } catch (err) {
      notify(
        "error",
        err?.response?.data?.error || "Failed to update AI settings"
      )
    } finally {
      setIsLoading(false)
    }
  }

  const columns = [
    {
      name: "Key",
      selector: row => row.key,
      sortable: true,
      width: "150px",
      center: true,
      cell: row => (
        <div className="d-flex align-items-center justify-content-center py-2">
          {row.key}
        </div>
      ),
    },
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      width: "200px",
      center: true,
      cell: row => (
        <div className="d-flex align-items-center justify-content-center py-2">
          {row.title}
        </div>
      ),
    },
    {
      name: "Prompts",
      selector: row => row.prompts,
      grow: 2,
      cell: row => (
        <div
          className="d-flex flex-wrap align-items-center py-2"
          style={{ gap: "8px" }}
        >
          {row.prompts.map((prompt, index) => (
            <span
              key={index}
              className="border rounded  px-3 py-1"
              style={{
                fontSize: "14px",
                borderColor: "#dee2e6",
                transition: "all 0.2s",
                display: "inline-block",
                backgroundColor: "#fff",
              }}
              onMouseEnter={e => {
                e.target.style.borderColor = "#5932ea"
              }}
              onMouseLeave={e => {
                e.target.style.borderColor = "#dee2e6"
              }}
            >
              {prompt}
            </span>
          ))}
        </div>
      ),
    },
    {
      name: "Actions",
      cell: row => (
        <div className="d-flex align-items-center justify-content-center gap-2 py-2">
          <Button
            onClick={() => {
              setSelectedConfig(row)
              setModal({ isOpen: true, mode: "edit" })
            }}
            className="has-icon mx-1"
            color="outline-primary"
          >
            <i className="fas fa-pencil-alt"></i>
          </Button>
          <Button
            onClick={() =>
              setDeleteConfig({
                id: row._id,
                confirm_alert: true,
                confirm_button_text: "Yes, remove it",
              })
            }
            className="has-icon mx-1"
            color="outline-danger"
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      ),
      width: "150px",
      right: true,
    },
  ]

  const settingsChanged =
    aiSettings.apiKey !== originalAISettings.apiKey ||
    aiSettings.model !== originalAISettings.model

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>AI Configuration | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center mb-4">
            <Col lg={6}>
              <div className="page-title-box">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                  AI Configuration
                </h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/#">TextLaunchPad</Link>
                  </li>
                  <li className="breadcrumb-item active">AI Configuration</li>
                </ol>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col lg={12}>
              <div className="c-card p-4">
                <Row className="align-items-center">
                  <Col lg={8} className="mb-3 mb-lg-0">
                    <FormGroup className="mb-0">
                      <Label>API Key</Label>
                      <div className="position-relative">
                        <Input
                          type={showApiKey ? "text" : "password"}
                          className="form-control pe-5"
                          value={aiSettings.apiKey}
                          onChange={e =>
                            setAISettings(prev => ({
                              ...prev,
                              apiKey: e.target.value,
                            }))
                          }
                        />
                        <Button
                          color="link"
                          className="position-absolute end-0 top-50 translate-middle-y px-3"
                          onClick={() => setShowApiKey(!showApiKey)}
                          style={{ zIndex: 1 }}
                        >
                          {showApiKey ? (
                            <EyeOff size={18} />
                          ) : (
                            <Eye size={18} />
                          )}
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>

                  <Col lg={4} className="mb-3 mb-lg-0">
                    <FormGroup className="mb-0">
                      <Label>Model</Label>
                      <Input
                        type="text"
                        value={aiSettings.model}
                        onChange={e =>
                          setAISettings(prev => ({
                            ...prev,
                            model: e.target.value,
                          }))
                        }
                      />
                    </FormGroup>
                  </Col>

                  {settingsChanged && (
                    <Row className="align-items-center justify-content-center gap-2 mt-4">
                      <Button
                        color="secondary"
                        className="main-btn secondary-btn"
                        onClick={() => setAISettings(originalAISettings)}
                        disabled={isLoading}
                      >
                        <span>Reset</span>
                      </Button>
                      <Button
                        color="primary"
                        className="main-btn"
                        onClick={handleSaveAISettings}
                        disabled={isLoading}
                      >
                        <span>Save Changes</span>
                      </Button>
                    </Row>
                  )}
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center mb-3">
            <Col sm={6}>
              <div className="search-box me-2 pb-2">
                <div className="position-relative">
                  <i className="fas fa-search search-icon"></i>
                  <Input
                    type="text"
                    className="form-control search-input"
                    placeholder="Search templates..."
                    value={searchQuery}
                    onChange={e => {
                      setSearchQuery(e.target.value)
                      setPage(1)
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="float-end">
                <button
                  className="main-btn"
                  type="button"
                  onClick={() => setModal({ isOpen: true, mode: "create" })}
                >
                  <img src={plus} alt="" />
                  <span>Add Template Prompts</span>
                </button>
              </div>
            </Col>
          </Row>

          {isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}

          <div className="c-card">
            <DataTable
              columns={columns}
              data={templatePrompts}
              pagination
              paginationServer
              paginationTotalRows={totalCount}
              onChangeRowsPerPage={setRowsPerPage}
              onChangePage={setPage}
              highlightOnHover
              className="border rounded"
              customStyles={{
                table: {
                  style: {
                    backgroundColor: "white",
                  },
                },
                headRow: {
                  style: {
                    backgroundColor: "#f8f9fa",
                    borderBottom: "1px solid #dee2e6",
                    minHeight: "50px",
                  },
                },
                headCells: {
                  style: {
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#495057",
                    padding: "12px 16px",
                  },
                },
                rows: {
                  style: {
                    minHeight: "60px",
                    "&:not(:last-of-type)": {
                      borderBottom: "1px solid #dee2e6",
                    },
                    "&:hover": {
                      backgroundColor: "#f8f9fa",
                    },
                  },
                },
                cells: {
                  style: {
                    padding: "12px 16px",
                  },
                },
              }}
            />
          </div>

          <Modal
            isOpen={modal.isOpen}
            toggle={handleCloseModal}
            centered
            className="c-modal modal-lg"
          >
            <div className="modal-body">
              <h5 className="modal-title mt-0 mb-3">
                {modal.mode === "create"
                  ? "Add Template Prompts"
                  : "Edit Template Prompts"}
              </h5>
              <button
                type="button"
                onClick={handleCloseModal}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={close} alt="" />
              </button>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="needs-validation"
              >
                <Row>
                  <Col>
                    <FormGroup className="mb-3">
                      <Label htmlFor="key">Key</Label>
                      <Controller
                        name="key"
                        control={control}
                        rules={{
                          required: "Please enter key",
                          pattern: {
                            value: /^\S*$/,
                            message: "Spaces are not allowed in key",
                          },
                        }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            className={`form-control ${
                              errors.key ? "is-invalid" : ""
                            }`}
                            placeholder="Enter key"
                          />
                        )}
                      />
                      {errors.key && (
                        <div className="invalid-feedback">
                          {errors.key.message}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup className="mb-3">
                      <Label htmlFor="title">Title</Label>
                      <Controller
                        name="title"
                        control={control}
                        rules={{ required: "Please enter title" }}
                        render={({ field }) => (
                          <Input
                            {...field}
                            type="text"
                            className={`form-control ${
                              errors.title ? "is-invalid" : ""
                            }`}
                            placeholder="Enter title"
                          />
                        )}
                      />
                      {errors.title && (
                        <div className="invalid-feedback">
                          {errors.title.message}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <FormGroup className="mb-3">
                      <Label>Prompts</Label>
                      {fields.map((field, index) => (
                        <div
                          key={field.id}
                          className="d-flex align-items-center gap-3 mb-2"
                        >
                          <div className="flex-grow-1">
                            <Controller
                              name={`prompts.${index}.value`}
                              control={control}
                              rules={{ required: "Please enter a prompt" }}
                              render={({ field }) => (
                                <textarea
                                  {...field}
                                  type="text"
                                  placeholder={`Enter prompt ${index + 1}`}
                                  className={`form-control ${
                                    errors.prompts?.[index]?.value
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                />
                              )}
                            />
                            {errors.prompts?.[index]?.value && (
                              <div className="invalid-feedback">
                                {errors.prompts[index].value.message}
                              </div>
                            )}
                          </div>
                          {fields.length > 1 && (
                            <Button
                              type="button"
                              color="danger"
                              className="btn-icon"
                              onClick={() => remove(index)}
                            >
                              <i className="fas fa-minus"></i>
                            </Button>
                          )}
                        </div>
                      ))}
                      <Button
                        type="button"
                        color="primary"
                        className="mt-3  main-btn"
                        onClick={() => append({ value: "" })}
                      >
                        <i className="fas fa-plus me-1"></i>
                        Add Prompt
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                  <Button
                    type="button"
                    className="main-btn secondary-btn"
                    onClick={handleCloseModal}
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button type="submit" color="primary" className="main-btn">
                    <span>Submit</span>
                  </Button>
                </div>
              </form>
            </div>
          </Modal>

          {deleteConfig.confirm_alert && (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText={deleteConfig.confirm_button_text}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              onConfirm={() => {
                handleDeleteConfig(deleteConfig.id)
                setDeleteConfig({ confirm_alert: false })
              }}
              onCancel={() => setDeleteConfig({ confirm_alert: false })}
            >
              You won't be able to revert this!
            </SweetAlert>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AIConfig
