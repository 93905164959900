import PropTypes, { func } from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  BreadcrumbItem,
  Alert,
  Label,
  FormGroup,
  Modal,
} from "reactstrap"
import { Link, Redirect } from "react-router-dom"
import axios from "axios"
import ReactQuill from "react-quill"
import { AvForm, AvField } from "availity-reactstrap-validation"
import applyIcon from "../../assets/images/apply-icon.svg"
import trashIcon from "../../assets/images/trash-icon.svg"
import purplePlus from "../../assets/images/purple-plus.svg"
import whitePlus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"
import greyCheck from "../../assets/images/grey-check.svg"
import greenCheck from "../../assets/images/green-check.svg"
import warningError from "../../assets/images/warning-error.svg"
import toast, { Toaster } from "react-hot-toast"
import "chartist/dist/scss/chartist.scss"
import { TemplatePrompts } from "constants/TemplatePrompts"

//i18n
import { withTranslation } from "react-i18next"

const UpdateDripAutomation = props => {
  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occurred")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }
  const quillRef = useRef(null)

  const dripTemplateId = window.location.pathname.split("/").pop()
  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  const defaultObjSchema = {
    day: 0,
    message: "",
    showTextSpinner: false,
    messageCursorPosition: 0,
    isOptOutOpen: false,
  }

  const tags = [
    "[@firstName]",
    "[@lastName]",
    "[@street]",
    "[@city]",
    "[@state]",
    "[@zip]",
    "[@custom]",
    "[@companyName]",
    "[@repNameAlias]",
  ]

  // const validation = {
  //   messageCharCount: 100,
  //   minTextSpinner: 2,
  //   minElementInTextSpinner: 2,
  // }
  const [menu, setMenu] = useState(false)
  const [reloadKey, setReloadKey] = useState(Math.random())
  const [dataArr, setDataArr] = useState([defaultObjSchema])
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [optOuts, setOptOuts] = useState([])
  const [dripName, setDripName] = useState("")
  const [activeIndex, setActiveIndex] = useState(null)
  const [alert, setAlert] = useState({ show: false })
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [validation, setValidation] = useState(null)
  const [aiTemplateModal, setAiTemplateModal] = useState({
    isOpen: false,
  })
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })
  const [templatePrompts, setTemplatePrompts] = useState([])
  const [aiSettings, setAISettings] = useState({ apiKey: "", model: "" })
  const messageTextAreaRef = useRef(null)

  const handleDayCount = (index, action, value) => {
    let tempArr = [...dataArr]
    if (action === "inc") {
      tempArr[index].day++
    } else if (action === "dec" && tempArr[index].day >= 1) {
      tempArr[index].day--
    } else if (action === "change") {
      tempArr[index].day = +value
    }
    setDataArr([...tempArr])
    // setReloadKey(Math.random())
  }

  const addMessageHandler = () => {
    let tempArr = [...dataArr]
    let nextDay = tempArr[tempArr.length - 1].day + 5
    tempArr.push({ ...defaultObjSchema, day: nextDay })
    setDataArr([...tempArr])
    setReloadKey(Math.random())
    setActiveIndex(tempArr.length - 1)
  }
  const handleAiTemplateSubmission = async (event, values) => {
    setAiTemplateModal(prevProps => {
      return { ...prevProps, isGenerating: true, ...values }
    })
    // return
    const mergeFields = tags
    const systemMessage = `You are an assistant that creates message templates. ${
      values.exampleTemplate
        ? "I will provide a reference template which you should use as a base while incorporating the requested changes/improvements."
        : "Create a template that is compelling and professional."
    }
    
      Guidelines:
      - Use merge fields in the format [@fieldName] for dynamic content Here are options for merge fields: ${JSON.stringify(
        mergeFields
      )}
      - Use text spinners in the format [option1/option2/option3] for variations
      - Keep the tone ${values.tone || "professional yet warm and engaging"}
      - Include clear calls-to-action
      - Maintain compliance with real estate marketing regulations
      - Avoid spam trigger words while maintaining marketing effectiveness
      - Message should not be more than 320 characters long
      - This template will be used for SMS
      - At least 2 text spinners with atleast 2 elements in each
      ${
        values.exampleTemplate
          ? "\nReference template: " + values.exampleTemplate
          : ""
      }
      ${
        values.comment || values.prompt
          ? "\nSpecific instructions: " + values.comment + " " + values.prompt
          : ""
      }
      
      Return ONLY the final template without any explanations or additional text.`
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: aiSettings.model,
          messages: [
            {
              role: "system",
              content: systemMessage,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${aiSettings.apiKey}`,
            "Content-Type": "application/json",
          },
        }
      )

      setAiTemplateModal(prevProps => {
        return {
          ...prevProps,
          isGenerating: false,
          isGenerated: true,
          generatedTemplate: response.data.choices[0].message.content,
        }
      })
      return response.data.choices[0].message.content
    } catch (error) {
      console.error("Error generating template with spinners:", error)
      return null
    }
  }
  const updateDripTemplate = async () => {
    const payload = {
      name: dripName,
      data: dataArr.map(item => {
        return { day: item.day, message: getCleanMessage(item.message) }
      }),
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT +
          "/api/dripTemplate/updateDripTemplate/" +
          dripTemplateId,
        payload,
        config
      )
      if (response.data) {
        notify("success", "Drip Template updated successfully!")
        // setAlert({
        //   show: true,
        //   text: "Drip Template updated successfully!",
        //   type: "success",
        // })
        setTimeout(() => {
          setShouldRedirect({
            state: true,
            path: `/drip-automation`,
          })
        }, 1500)
      }
    } catch (error) {
      notify("error", err?.response?.data?.error)
      // setAlert({
      //   show: true,
      //   text: "An unknown error occurred!",
      //   type: "danger",
      // })

      // setTimeout(() => {
      //   setAlert({
      //     show: false,
      //   })
      // }, 1500)
    }
  }

  const removeMessageHandler = index => {
    let tempArr = [...dataArr]
    tempArr.splice(index, 1)
    setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0)
    setDataArr([...tempArr])
    setReloadKey(Math.random())
  }

  const toggle = () => {
    setMenu(!menu)
  }

  const getNegativeKeywords = async () => {
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/listNegativeKeywords/",
      config
    )
    if (response.data) {
      setNegativeKeywords(response.data.data)
    }
  }

  const getOptOuts = async () => {
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullOptOut",
      config
    )
    if (response.data) {
      setOptOuts(response.data.data)
    }
  }

  const getDripTemplate = async () => {
    const response = await axios.get(
      process.env.REACT_APP_PORT +
        "/api/dripTemplate/pullDripTemplate/" +
        dripTemplateId,
      config
    )
    if (response.data) {
      setDripName(response.data.data.name)
      setDataArr(response.data.data.data)
      setActiveIndex(0)
    }
  }

  const addTextSpinnerHandler = index => {
    const tempArr = [...dataArr]
    tempArr[index].showTextSpinner = true
    setDataArr([...tempArr])
    setReloadKey(Math.random())
  }

  function addSpinnerTextHandler(arr, index) {
    let tempArr = [...dataArr]
    let str = `[${arr.join("/")}]`
    // let obj = tempArr[index]

    // str =
    //   obj.message.slice(0, obj.messageCursorPosition) +
    //   str +
    //   obj.message.slice(obj.messageCursorPosition)

    // tempArr[index].message = str
    tempArr[index].showTextSpinner = false
    // tempArr[index].messageCursorPosition = str.length
    setDataArr([...tempArr])
    setReloadKey(Math.random())
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      const range = editor.getSelection()
      const position = range
        ? range.index
        : editor.getText().replaceAll("\n", "").length
      const positionEnd = range ? range.length : null

      if (!isNaN(position) && !isNaN(positionEnd)) {
        editor.deleteText(position, positionEnd)
      }
      editor.insertText(position, str, "user")
      editor.setSelection(position + str.length)
    }
  }
  function getCleanMessage(_mssg = "") {
    const tempElement = document.createElement("div")
    tempElement.innerHTML = _mssg
    return tempElement.innerText
  }

  function closeSpinnerTextHandler(index) {
    let tempArr = [...dataArr]
    tempArr[index].showTextSpinner = false
    setDataArr([...tempArr])
    setReloadKey(Math.random())
  }

  function handleDataChange(index, key, val) {
    if (dataArr[0].day) {
      let tempArr = [...dataArr]
      tempArr[index][key] = val
      setDataArr([...tempArr])
      setReloadKey()
    }
  }

  function updateMessageCursorPosition(index, position) {
    let tempArr = [...dataArr]
    tempArr[index].messageCursorPosition = position
    setDataArr([...tempArr])
    // setReloadKey(Math.random())
  }

  // function addTag(tag, index) {
  //   let tempArr = [...dataArr]
  //   tempArr[index].message =
  //     tempArr[index].message?.slice(0, tempArr[index].messageCursorPosition) +
  //     tag +
  //     tempArr[index].message?.slice(tempArr[index].messageCursorPosition)

  //   setDataArr([...tempArr])
  //   setReloadKey(Math.random())
  //   updateMessageCursorPosition(index, tempArr[index].message.length)

  //   // this.setState({
  //   //   message:
  //   //     this.state.message?.slice(0, this.state.messageCursorPosition) +
  //   //     tag +
  //   //     this.state.message?.slice(this.state.messageCursorPosition),
  //   //   charCount: (this.state.message + tag)?.length,
  //   // })
  // }

  function addTag(tag, index) {
    const editor = quillRef.current.getEditor()
    const range = editor.getSelection()
    const position = range
      ? range.index
      : getMessageCharCount(dataArr[index].message)
    const positionEnd = range ? range.length : null
    if (!isNaN(position) && !isNaN(positionEnd)) {
      editor.deleteText(position, positionEnd)
    }
    editor.insertText(position, tag, "user")
    editor.setSelection(position + tag.length)
  }

  function getMessageCharCount(_mssg = "") {
    const tempElement = document.createElement("div")
    tempElement.innerHTML = _mssg
    let mssg = tempElement.innerText
    const textSpinners = []
    const mssgWithoutTextSpinner = mssg.replace(
      /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g,
      match => {
        textSpinners.push(match)
        return ""
      }
    )
    const textSpinnerCharCounts = sumArray(
      textSpinners.map(item => {
        let arr = item.replace("[", "").replace("]", "").split("/")
        let maxLength = 0
        for (let i = 0; i < arr.length; i++) {
          const str = arr[i]
          const length = str.length
          if (length > maxLength) {
            maxLength = length
          }
        }
        return maxLength
      })
    )
    return mssgWithoutTextSpinner?.length + textSpinnerCharCounts
  }

  function sumArray(arr) {
    let sum = 0
    for (let i = 0; i < arr.length; i++) {
      const num = arr[i]
      if (typeof num === "number") {
        sum += num
      }
    }
    return sum
  }

  function getTextSpinnerCount(str = "") {
    const pattern = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    const matches = str.match(pattern)
    if (matches) {
      return matches?.length
    } else {
      return 0
    }
  }

  function isTextSpinnerHasMinElement(mssg = "", min = 2) {
    if (!mssg.length) {
      return false
    }
    const pattern = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    const matches = mssg.match(pattern)
    let flag = true
    if (matches) {
      matches.forEach(item => {
        if (item.split("/").length < min) flag = false
      })
    }
    return flag
  }

  function findAllIndices(text, nw) {
    const indices = []
    const regex = new RegExp(`\\b${nw}\\b`, "gi") // 'gi' makes it case-insensitive
    let match

    // Use regex to find all exact matches of the word
    while ((match = regex.exec(text)) !== null) {
      indices.push(match.index)
    }

    return indices
  }
  function checkNegativeKeywords(_val = "") {
    const found = []
    const val = getCleanMessage(_val)
    const valArr = val?.split(" ")?.map(item => item.toLowerCase())
    for (const word of negativeKeywords) {
      if (valArr.includes(word?.toLowerCase())) {
        found.push(word)
      }
    }
    if (found && found.length) {
      // this.setState({
      //   negativeKeywords: found,
      //   messageError: `Negative Kewords Found: ${found.join(", ")}`,
      // })
      return false
    } else {
      // this.setState({
      //   negativeKeywords: found,
      //   messageError: "",
      // })
      return true
    }
  }

  function getMergeFieldCount(str = "") {
    const pattern = /\[\@[A-Za-z0-9]+\]/g
    const matches = str.match(pattern)
    if (matches) {
      return matches?.length
    } else {
      return 0
    }
  }

  function isMergeFieldValid(mssg = "") {
    const pattern = /\[\@[A-Za-z0-9]+\]/g
    const matches = mssg.match(pattern)
    if (matches) {
      return matches.every(item => tags.includes(item))
    } else {
      return true
    }
  }

  function isMessageComplete(mssg = "") {
    if (
      validation?.messageCharCount &&
      getMessageCharCount(mssg) < validation?.messageCharCount
    )
      return false
    if (
      validation?.minTextSpinner &&
      getTextSpinnerCount(mssg) < validation?.minTextSpinner
    )
      return false
    if (validation?.isNoNegativeKeywordRequired && !checkNegativeKeywords(mssg))
      return false
    if (validation?.isMergeFieldRequired && !getMergeFieldCount(mssg))
      return false
    if (validation?.isMergeFieldRequired && !getMergeFieldCount(mssg))
      return false
    if (validation?.validMergeFieldRequired && !isMergeFieldValid(mssg))
      return false
    return true
  }

  function checkValidSubmit() {
    let isValid = true
    if (!(dripName && dripName.trim().length) || !checkDuplicateDays(dataArr)) {
      return false
    }
    dataArr.forEach(item => {
      if (
        !isMessageComplete(item.message) ||
        !(item.day > 0 && item.day < 366)
      ) {
        isValid = false
      }
    })
    return isValid
  }

  function checkDuplicateDays(arr) {
    let tempArr = arr.map(item => {
      return item.day
    })
    return !(tempArr.length !== new Set(tempArr).size)
  }

  function minimizeAll() {
    setActiveIndex(null)
  }
  const getCategories = async () => {
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullCategories",
      config
    )
    if (response) {
      setCategories(response.data.data)
    }
  }
  function isPresent(word, string) {
    const val = getCleanMessage(string)
    const lowerStr = val.toLowerCase()
    const lowerWord = word.toLowerCase()
    const pattern = new RegExp(`\\b${lowerWord}\\b`)
    return pattern.test(lowerStr)
  }
  const handleTextEditorChange = () => {
    const editor = quillRef.current.getEditor()

    const text = editor.getText()

    editor.formatText(0, text.length, {
      color: "black",
      background: false,
    })

    // For merge field
    let regex = /\{\{[^{}]+\}\}|\[@[^\[\]]+\]/g
    let match
    // Apply formatting to matched patterns
    while ((match = regex.exec(text)) !== null) {
      editor.formatText(match.index, match[0].length, {
        color: "#5932EA",
        // background: "#E6F3FF",
      })
    }
    // For text spinner
    regex = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    while ((match = regex.exec(text)) !== null) {
      editor.formatText(match.index, match[0].length, {
        color: "#008000",
        // background: "#E6F3FF",
      })
    }
    negativeKeywords.forEach(nw => {
      const indexs = findAllIndices(text.toLowerCase(), nw.toLowerCase())
      if (indexs.length) {
        indexs.forEach(index => {
          editor.formatText(index, nw.length, {
            color: "#D80415",
            // background: "#E6F3FF",
          })
        })
      }
    })
  }

  useEffect(() => {
    getNegativeKeywords()
    getOptOuts()
    getDripTemplate()
    getCategories()
    getTemplatePrompts()
    getAISettings()
  }, [])

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      editor.on("text-change", function (delta, oldDelta, source) {
        if (source === "user") {
          const text = editor.getText()
          editor.formatText(0, text.length, {
            color: "black",
            background: false,
          })

          // For merge field
          let regex = /\{\{[^{}]+\}\}|\[@[^\[\]]+\]/g
          let match
          // Apply formatting to matched patterns
          while ((match = regex.exec(text)) !== null) {
            editor.formatText(match.index, match[0].length, {
              color: "#5932EA",
              // background: "#E6F3FF",
            })
          }
          // For text spinner
          regex = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
          while ((match = regex.exec(text)) !== null) {
            editor.formatText(match.index, match[0].length, {
              color: "#008000",
              // background: "#E6F3FF",
            })
          }
          negativeKeywords.forEach(nw => {
            const indexs = findAllIndices(text.toLowerCase(), nw.toLowerCase())
            if (indexs.length) {
              indexs.forEach(index => {
                editor.formatText(index, nw.length, {
                  color: "#D80415",
                  // background: "#E6F3FF",
                })
              })
            }
          })
        }
      })
    }
  }, [reloadKey, negativeKeywords, activeIndex])

  useEffect(() => {
    if (quillRef.current) {
      handleTextEditorChange()
    }
  }, [quillRef.current, activeIndex])

  const getTemplatePrompts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/templatePrompts/getAllTemplatePrompts`,
        config
      )
      if (response.data?.templatePrompts?.length) {
        setTemplatePrompts(response.data.templatePrompts)
      } else {
        setTemplatePrompts(TemplatePrompts)
      }
    } catch (err) {
      setTemplatePrompts(TemplatePrompts)
      notify(
        "error",
        err?.response?.data?.error || "Failed to fetch template prompt"
      )
    }
  }

  const getAISettings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/templatePrompts/getAISettings`,
        config
      )
      setAISettings(response.data?.aiSettings)
    } catch (err) {
      notify(
        "error",
        err?.response?.data?.error || "Failed to fetch AI settings"
      )
    }
  }

  const customModules = {
    toolbar: false,
  }
  const customFormats = ["bold", "italic", "color"]
  return (
    <React.Fragment>
      <Toaster />
      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
      <Modal
        isOpen={aiTemplateModal?.isOpen}
        toggle={() => {
          setAiTemplateModal(prevProps => {
            return { ...prevProps, isOpen: !prevProps?.isOpen }
          })
        }}
        // backdrop="static"
        centered={true}
        className="c-modal modal-lg"
      >
        {aiTemplateModal.isGenerated ? (
          <div className="modal-body">
            <h5 className="modal-title text-center">
              <i className="fas fa-magic color-primary me-2"></i>
              Template Generated
            </h5>
            <p className="text-center">
              Template Generated as per your given instructions.
            </p>
            <button
              type="button"
              onClick={() => {
                setAiTemplateModal({
                  isOpen: false,
                  exampleTemplate: dataArr[0]?.message,
                })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            <div className="message-text-editor p-3 my-4">
              {aiTemplateModal.generatedTemplate}
            </div>
            <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2 mt-3">
              <Button
                className="main-btn secondary-btn"
                onClick={() => {
                  setAiTemplateModal(prevProps => {
                    return {
                      ...prevProps,
                      isGenerated: false,
                    }
                  })
                }}
                role="button"
              >
                <span>Generate Again</span>
              </Button>
              <Button
                role="button"
                color="primary"
                className="main-btn"
                onClick={() => {
                  // handleDataChange(
                  //   0,
                  //   "message",
                  //   aiTemplateModal.generatedTemplate
                  // )
                  navigator.clipboard
                    .writeText(aiTemplateModal.generatedTemplate)
                    .then(() =>
                      notify("success", "Template copied to clipboard")
                    )
                    .catch(() => notify("error", "Failed to copy template"))
                  setAiTemplateModal({ isOpen: false })
                }}
              >
                <span>Finish</span>
              </Button>
            </div>
          </div>
        ) : (
          <>
            {aiTemplateModal.isGenerating ? (
              <div className="modal-body">
                <h5 className="modal-title text-center">
                  <i className="fas fa-magic color-primary me-2"></i>
                  Generating Template
                </h5>
                <p className="text-center">
                  Please hold on while AI is generating template for you as per
                  your given input.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    setAiTemplateModal({
                      isOpen: false,
                      exampleTemplate: dataArr[0]?.message,
                    })
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={close} alt="" />
                </button>
                <div className="d-flex justify-content-center py-5">
                  <iframe src="https://lottie.host/embed/21837365-bec4-412b-a5f1-48188e4c0dfb/KSGR2WWQ7g.json"></iframe>
                </div>
              </div>
            ) : (
              <div className="modal-body">
                <h5 className="modal-title">
                  <i className="fas fa-magic color-primary me-2"></i>
                  Generate Template By AI
                </h5>
                <p>Enter the template example and extra comments below.</p>
                <button
                  type="button"
                  onClick={() => {
                    setAiTemplateModal({
                      isOpen: false,
                      exampleTemplate: dataArr[0]?.message,
                    })
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={close} alt="" />
                </button>
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleAiTemplateSubmission(e, v)
                  }}
                  className="needs-validation"
                >
                  <Row>
                    <Col xl={12} md={12} sm={12}>
                      <FormGroup>
                        <AvField
                          rows={5}
                          name="exampleTemplate"
                          placeholder="Reference Template"
                          type="textarea"
                          errorMessage="Enter Reference Template"
                          className="form-control cc-form-control"
                          validate={{
                            required: { value: false },
                          }}
                          id="validationCustom01"
                          defaultValue={aiTemplateModal.exampleTemplate}
                        />
                      </FormGroup>
                    </Col>

                    <Col xl={12} md={12} sm={12}>
                      <FormGroup>
                        <Label>Select Template Category</Label>
                        <AvField
                          type="select"
                          name="templateCategory"
                          errorMessage="This field is required "
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={aiTemplateModal.templateCategory || ""}
                          onChange={e => {
                            setAiTemplateModal(prevProps => {
                              return {
                                ...prevProps,
                                templateCategory: e.target.value,
                              }
                            })
                          }}
                        >
                          <option value="">Select Category</option>
                          {templatePrompts.map((item, index) => {
                            return (
                              <option value={item.key} key={index}>
                                {item.title}
                              </option>
                            )
                          })}
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col xl={12} md={12} sm={12}>
                      <FormGroup>
                        <Label>Select Prompt</Label>
                        <AvField
                          type="select"
                          name="prompt"
                          errorMessage="This field is required "
                          // validate={{
                          //   required: { value: true },
                          // }}
                          value={aiTemplateModal.prompt || ""}
                        >
                          <option value="">Choose a specific prompt</option>
                          {templatePrompts
                            .filter(item => {
                              return (
                                item.key == aiTemplateModal.templateCategory
                              )
                            })?.[0]
                            ?.prompts?.map((item, index) => {
                              return (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              )
                            })}
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col xl={12} md={12} sm={12}>
                      <FormGroup>
                        <AvField
                          name="comment"
                          placeholder="Enter comments and suggestions here"
                          type="textarea"
                          errorMessage="Enter Comment"
                          className="form-control cc-form-control"
                          validate={{
                            required: { value: false },
                          }}
                          id="validationCustom01"
                          defaultValue={""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2 mt-3">
                    <Button
                      className="main-btn secondary-btn"
                      onClick={() => {
                        setAiTemplateModal({ isOpen: false })
                      }}
                      role="button"
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button type="submit" color="primary" className="main-btn">
                      <span>Generate</span>
                    </Button>
                  </div>
                </AvForm>
              </div>
            )}
          </>
        )}
      </Modal>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          {/* <div className="page-title-box">

            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to TextLaunchPad Dashboard</li>
                </ol>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Dropdown isOpen={menu} toggle={toggle}>
                    <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
                      <i className="mdi mdi-cog me-2"></i> Settings
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag="a" href="#">Action</DropdownItem>
                      <DropdownItem tag="a" href="#">Another action</DropdownItem>
                      <DropdownItem tag="a" href="#">Something else here</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag="a" href="#">Separated link</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div> */}

          <div className="page-title-box mb-1">
            <Row className="align-items-center">
              <Col md={8}>
                <h4 className="font-size-18">Edit Drip Template</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>

                  <BreadcrumbItem active>Drip Automations </BreadcrumbItem>
                </ol>
                {/* <ol className="breadcrumb m-0">
        <li className="breadcrumb-item active">Welcome to TextLaunchPad Dashboard</li>
      </ol> */}
              </Col>

              <Col md="12" sm="12" lg="12" xl="12" className="mt-4">
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="drip-aut-main mt-1">
            <div className="row">
              <div className="col-lg-8 offset-lg-0">
                <ul className="d-aut-wrapper">
                  <li className="d-aut-el">
                    <div className="form-group style-2">
                      <div className="inp-grp">
                        <input
                          type="text"
                          placeholder="Drip Automations Name"
                          value={dripName}
                          onChange={e => setDripName(e.target.value)}
                        />
                      </div>
                    </div>
                  </li>
                  <span key={reloadKey}>
                    {dataArr &&
                      dataArr.map((item, index) => {
                        return (
                          <span key={index} style={{ zIndex: 100 - index }}>
                            <li key={index}>
                              {activeIndex === index ? (
                                <div className="d-aut-main">
                                  <span className="d-num-in">
                                    Message {index + 1}
                                  </span>
                                  <div className="d-aut-head">
                                    <p>Send On Day</p>
                                    <div className="d-aut-counter">
                                      <button
                                        className="dec"
                                        onClick={() =>
                                          handleDayCount(index, "dec")
                                        }
                                      >
                                        -
                                      </button>
                                      <input
                                        type="number"
                                        value={item.day}
                                        onChange={e => {
                                          handleDayCount(
                                            index,
                                            "change",
                                            e.target.value
                                          )
                                        }}
                                      />
                                      <button
                                        className="inc"
                                        onClick={() =>
                                          handleDayCount(index, "inc")
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                    <p>
                                      After Prospect has been added to the drip
                                      automation
                                    </p>
                                    <div className="actions">
                                      <button
                                        className="actions-btn"
                                        onClick={() => {
                                          minimizeAll()
                                        }}
                                      >
                                        <img src={applyIcon} alt="" />
                                      </button>
                                      {dataArr.length > 1 && (
                                        <button
                                          className="actions-btn"
                                          onClick={() => {
                                            removeMessageHandler(index)
                                          }}
                                        >
                                          <img src={trashIcon} alt="" />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-aut-body">
                                    {item.showTextSpinner ? (
                                      <TextSpinner
                                        addSpinnerTextHandler={
                                          addSpinnerTextHandler
                                        }
                                        closeSpinnerTextHandler={
                                          closeSpinnerTextHandler
                                        }
                                        negativeKeywords={negativeKeywords}
                                        mssgIndex={index}
                                        validation={validation}
                                      />
                                    ) : null}
                                    <>
                                      {/* <textarea
                                        ref={messageTextAreaRef}
                                        name=""
                                        id=""
                                        rows="5"
                                        defaultValue={item.message}
                                        onChange={e => {
                                          handleDataChange(
                                            index,
                                            "message",
                                            e.target.value
                                          )
                                          updateMessageCursorPosition(
                                            index,
                                            e.target.selectionStart
                                          )
                                        }}
                                        onClick={e => {
                                          updateMessageCursorPosition(
                                            index,
                                            e.target.selectionStart
                                          )
                                        }}
                                      ></textarea> */}
                                      <ReactQuill
                                        key={reloadKey}
                                        placeholder="Write message..."
                                        ref={quillRef}
                                        value={item.message}
                                        className="message-text-editor"
                                        onChange={value => {
                                          handleDataChange(
                                            index,
                                            "message",
                                            value
                                          )
                                        }}
                                        modules={customModules}
                                        formats={customFormats}
                                        theme="snow"
                                      />
                                      <div className="d-aut-ac-wraps">
                                        <button
                                          onClick={() =>
                                            setAiTemplateModal({
                                              isOpen: true,
                                              exampleTemplate: getCleanMessage(
                                                dataArr[index]?.message
                                              ),
                                            })
                                          }
                                        >
                                          <i className="fas fa-magic color-primary"></i>

                                          <span>Generate Template</span>
                                        </button>

                                        <Dropdown
                                          isOpen={false}
                                          toggle={() => {
                                            handleDataChange(
                                              index,
                                              "isMergeFieldOpen",
                                              !item.isMergeFieldOpen
                                            )
                                          }}
                                        >
                                          <DropdownToggle
                                            className="btn "
                                            caret
                                            color={"outline-primary"}
                                          >
                                            Merge Fields{" "}
                                            <i className="mdi mdi-chevron-down" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            style={{ zIndex: 5 }}
                                            className={
                                              item.isMergeFieldOpen
                                                ? "d-flex flex-column gap-1 p-2"
                                                : ""
                                            }
                                          >
                                            {tags?.length > 0 &&
                                              tags.map((item, tagIndex) => (
                                                <DropdownItem
                                                  type={"button"}
                                                  className="drp-dwn-item"
                                                  onClick={() => {
                                                    addTag(item, index)
                                                  }}
                                                  key={tagIndex}
                                                >
                                                  {item}
                                                </DropdownItem>
                                              ))}
                                          </DropdownMenu>
                                        </Dropdown>

                                        <Dropdown
                                          isOpen={item.isOptOutOpen}
                                          toggle={() => {
                                            handleDataChange(
                                              index,
                                              "isOptOutOpen",
                                              !item.isOptOutOpen
                                            )
                                          }}
                                        >
                                          <DropdownToggle
                                            className="btn "
                                            caret
                                            color={"outline-primary"}
                                          >
                                            OptOut Languages{" "}
                                            <i className="mdi mdi-chevron-down" />
                                          </DropdownToggle>
                                          <DropdownMenu
                                            className={
                                              item.isOptOutOpen
                                                ? "d-flex flex-column gap-1 p-2"
                                                : ""
                                            }
                                          >
                                            {optOuts?.length > 0 &&
                                              optOuts.map(item => (
                                                <DropdownItem
                                                  onClick={e => {
                                                    addTag(
                                                      "[@" + item.title + "]",
                                                      index
                                                    )
                                                  }}
                                                  type={"button"}
                                                  className="drp-dwn-item"
                                                  key={item._id}
                                                >
                                                  {item.title}
                                                </DropdownItem>
                                              ))}
                                          </DropdownMenu>
                                        </Dropdown>
                                        {/* </div> */}

                                        <button
                                          onClick={() =>
                                            addTextSpinnerHandler(index)
                                          }
                                        >
                                          <img src={purplePlus} alt="" />
                                          <span>Add Text Spinner</span>
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleDataChange(
                                              index,
                                              "showNegativeKeyword",
                                              !item.showNegativeKeyword
                                            )
                                          }
                                        >
                                          {item.showNegativeKeyword ? (
                                            <i className="fas fa-eye-slash color-primary"></i>
                                          ) : (
                                            <i className="fas fa-eye color-primary"></i>
                                          )}
                                          <span>
                                            {item.showNegativeKeyword
                                              ? "Hide"
                                              : "Show"}{" "}
                                            negative keywords
                                          </span>
                                        </button>
                                      </div>
                                      {item.showNegativeKeyword && (
                                        <table className="d-flex flex-wrap p-0 ">
                                          <tbody>
                                            <tr className="d-flex flex-wrap py-3 gap-2">
                                              <th>Negative Keywords:</th>
                                              {negativeKeywords.map(
                                                (nk, nkIndex) => (
                                                  <td
                                                    className={`px-1 bg-light rounded-1 ${
                                                      isPresent(
                                                        nk,
                                                        item.message
                                                      )
                                                        ? "badge-soft-danger"
                                                        : "bg-light "
                                                    }`}
                                                    value={nkIndex}
                                                    key={nkIndex}
                                                  >
                                                    {nk}
                                                  </td>
                                                )
                                              )}
                                            </tr>
                                          </tbody>
                                        </table>
                                      )}
                                    </>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`d-aut-main mb-4 ${
                                    !isMessageComplete(item.message) ||
                                    !(item.day > 0 && item.day < 366)
                                      ? "border-danger"
                                      : "border-info"
                                  }  border-start ps-2 border-2`}
                                  onClick={() => setActiveIndex(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="d-aut-head flex-column align-items-start gap-2">
                                    <span className="text-black small ">
                                      Message {index + 1}
                                    </span>
                                    {!isMessageComplete(item.message) ||
                                    !(item.day > 0 && item.day < 366) ? (
                                      <p className="text-danger d-flex gap-2 align-items-center">
                                        <img
                                          src={warningError}
                                          width={"20px"}
                                          alt=""
                                        />
                                        Message not completed
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <p style={{ wordWrap: "anywhere" }}>
                                      {getCleanMessage(item.message)}
                                    </p>
                                    <p
                                      className={
                                        !isMessageComplete(item.message) ||
                                        !(item.day > 0 && item.day < 366)
                                          ? "text-danger"
                                          : "text-info"
                                      }
                                    >
                                      Day {item.day}
                                    </p>
                                  </div>
                                  {/* <div className="d-aut-body">
                                
                                </div> */}
                                </div>
                              )}
                            </li>
                          </span>
                        )
                      })}
                  </span>
                  <li className="d-aut-el" style={{ zIndex: 0 }}>
                    <button className="main-btn transparent">
                      <span className="text-black">
                        Message Count:
                        <strong className="text-info"> {dataArr.length}</strong>
                      </span>
                    </button>
                  </li>
                  <li className="d-aut-el" style={{ zIndex: 0 }}>
                    <button
                      className="main-btn"
                      onClick={() => {
                        addMessageHandler()
                      }}
                    >
                      <img src={whitePlus} alt="" />
                      <span>Add More</span>
                    </button>
                  </li>

                  <li className="" style={{ zIndex: 0 }}>
                    <div className="d-flex gap-3">
                      <button
                        className={`main-btn mt-3 ${
                          !checkValidSubmit() ? "opacity-50" : ""
                        }`}
                        onClick={() => {
                          updateDripTemplate()
                        }}
                        disabled={!checkValidSubmit()}
                      >
                        {/* <img src={whitePlus} alt="" /> */}
                        <span>Update</span>
                      </button>
                      <Link
                        className="main-btn mt-3 secondary-btn"
                        to="/drip-automation"
                      >
                        {/* <img src={whitePlus} alt="" /> */}
                        <span>Cancel</span>
                      </Link>
                    </div>
                    {alert.show && (
                      <Alert color={alert.type} className="mt-3">
                        {alert.text}
                      </Alert>
                    )}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-12">
                <div className="c-card t-sticky">
                  <div className="form-group style-2">
                    <div className="inp-grp">
                      <select
                        onChange={e => {
                          const category = categories.filter(
                            item => item._id === e.target.value
                          )
                          setSelectedCategory(
                            category && category.length ? category[0] : null
                          )
                          setValidation(category[0]?.validations)
                        }}
                        className="form-control cc-form-control"
                        // style={{ background: "white" }}
                      >
                        <option value="">Select Category</option>
                        {categories.length > 0 &&
                          categories.map(item => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {!selectedCategory ? (
                    <div className="text-warning text-center">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      Select Category to see validations
                    </div>
                  ) : selectedCategory?.validations ? (
                    <>
                      {dataArr[activeIndex] ? (
                        <ul className="check-list">
                          <li
                            className={
                              dataArr[activeIndex]?.day > 0 &&
                              dataArr[activeIndex]?.day < 366
                                ? "active"
                                : ""
                            }
                          >
                            <span>Day must be between 1 to 365</span>
                          </li>
                          <li
                            className={
                              checkDuplicateDays(dataArr) ? "active" : ""
                            }
                          >
                            <span>Days must not be similar</span>
                          </li>
                          {validation?.minChar && (
                            <li
                              className={
                                getMessageCharCount(
                                  dataArr[activeIndex]?.message
                                ) >= validation?.minChar
                                  ? "active"
                                  : ""
                              }
                            >
                              <span>
                                Minimum of {validation?.minChar} characters
                              </span>
                            </li>
                          )}
                          {validation.minTextSpinner && (
                            <li
                              className={
                                getTextSpinnerCount(
                                  dataArr[activeIndex]?.message
                                ) >= validation?.minTextSpinner
                                  ? "active"
                                  : ""
                              }
                            >
                              <span>
                                At least {validation?.minTextSpinner} text
                                spinners
                              </span>
                            </li>
                          )}
                          {validation.minElementInTextSpinner && (
                            <li
                              className={
                                isTextSpinnerHasMinElement(
                                  dataArr[activeIndex]?.message,
                                  validation?.minElementInTextSpinner
                                )
                                  ? "active"
                                  : ""
                              }
                            >
                              <span>
                                Each text spinner must have at least{" "}
                                {validation?.minElementInTextSpinner} elements
                              </span>
                            </li>
                          )}
                          {validation.isNoNegativeKeywordRequired && (
                            <li
                              className={
                                checkNegativeKeywords(
                                  dataArr[activeIndex]?.message
                                )
                                  ? "active"
                                  : false
                              }
                            >
                              <span>
                                Must have no negative/restricted keyword
                              </span>
                            </li>
                          )}
                          {validation?.isMergeFieldRequired && (
                            <li
                              className={
                                getMergeFieldCount(
                                  dataArr[activeIndex]?.message
                                )
                                  ? "active"
                                  : ""
                              }
                            >
                              <span> Must have Merge Field</span>
                            </li>
                          )}

                          {validation?.validMergeFieldRequired && (
                            <li
                              className={
                                isMergeFieldValid(dataArr[activeIndex]?.message)
                                  ? "active"
                                  : ""
                              }
                            >
                              <span> All Merge Fields must be valid</span>
                            </li>
                          )}
                        </ul>
                      ) : (
                        <>
                          <ul className="check-list">
                            <li
                              className={
                                checkDuplicateDays(dataArr) ? "active" : ""
                              }
                            >
                              <span>Days must not be similar</span>
                            </li>
                          </ul>
                          {/* Select Message to see more validations */}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="d-flex text-danger align-items-center mt-4">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      To see validations, update validation of this category
                      first.
                      <Link
                        to={{
                          pathname: "/templates-setting",
                        }}
                      >
                        Update now{" "}
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const TextSpinner = ({
  negativeKeywords,
  addSpinnerTextHandler,
  closeSpinnerTextHandler,
  mssgIndex,
  validation,
}) => {
  const [inputs, setInputs] = useState(["", "", ""])
  const [error, setError] = useState("")

  const inputChangeHandler = (index, val) => {
    const inputArr = [...inputs]
    inputArr[index] = val
    setInputs(inputArr)
  }

  const toggleInput = (operation, index = null) => {
    switch (operation) {
      case "add": {
        setInputs([...inputs, ""])
        break
      }
      case "remove": {
        const inputArr = [...inputs]
        inputArr.splice(index, 1)
        setInputs(inputArr)
        break
      }
      case "delete": {
        setInputs([])
        closeSpinnerTextHandler(mssgIndex)
        break
      }
      case "save": {
        const inputArr = inputs.filter(i => i.trim().length > 0)
        if (
          validation?.minElementInTextSpinner &&
          inputArr.length <= validation?.minElementInTextSpinner
        ) {
          setError(
            `Must have at least ${validation?.minElementInTextSpinner} elements`
          )
        } else if (inputArr.length !== new Set(inputArr).size) {
          setError("Elements must be unique")
        } else {
          const val = inputArr.join(" ")
          const negativeKeywordsFound = negativeKeywords.filter(word =>
            val?.toLowerCase().includes(word?.toLowerCase())
          )
          if (negativeKeywordsFound.length) {
            setError(
              `Negative keywords found: ${negativeKeywordsFound.join(", ")}`
            )
          } else {
            setInputs([])
            addSpinnerTextHandler(inputArr, mssgIndex)
          }
        }
        // addSpinnerTextHandler(inputArr)

        break
      }
      default:
        break
    }
  }

  return (
    <div className="text-spinner-wr py-4 px-2">
      <div className="d-flex flex-wrap gap-2">
        {inputs.map((input, index) => (
          <div key={index} className="d-flex gap-1">
            <input
              value={input}
              placeholder="word or phrase"
              onChange={e => inputChangeHandler(index, e.target.value)}
              className="border-start-0 border-end-0 border-top-0 border-top-1 border me-2 small"
              onKeyDown={e => {
                if (e.keyCode === 13) e.preventDefault()
              }}
            />
            {index >= 3 ? (
              <Button
                className={"has-icon me-1 small text-danger bg-transparent"}
                color={"danger"}
                title="Remove"
                onClick={() => toggleInput("remove", index)}
              >
                <i className="fas fa-times"></i>
              </Button>
            ) : null}
            /
          </div>
        ))}
      </div>
      {inputs && inputs.length ? (
        <div className="d-flex gap-2 p-3">
          <Button
            className={"has-icon me-1 btn-theme-color"}
            color={"success"}
            title="Add"
            onClick={() => {
              toggleInput("add")
            }}
          >
            <i className="fas fa-plus"></i>
          </Button>

          <Button
            className={"has-icon me-1"}
            color={"danger"}
            title="Delete"
            onClick={() => {
              toggleInput("delete")
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
          <Button
            className={"has-icon me-1 btn-theme-color"}
            color={"success"}
            title="Save"
            onClick={() => {
              toggleInput("save")
            }}
          >
            <i className="fas fa-save"></i>
          </Button>
        </div>
      ) : null}
      {inputs && inputs.length && error ? (
        <div className="text-danger">{error}</div>
      ) : null}
    </div>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(UpdateDripAutomation)
