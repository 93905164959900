import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { get } from "helpers/api_helper"
import dashboardIcon from "../../assets/images/dashboard.svg"
import campaignIcon from "../../assets/images/campaigns.svg"
import prospectIcon from "../../assets/images/prospects.svg"
import marketConfigIcon from "../../assets/images/marketConfig.svg"
import numbersIcon from "../../assets/images/numbers.svg"
import templatesIcon from "../../assets/images/templates.svg"
import notificationIcon from "../../assets/images/notification.svg"
import settingicon from "../../assets/images/settings.svg"
import usericon from "../../assets/images/users-icon.svg"
import allUnreadIcon from "../../assets/images/all-unread.svg"
import verifiedContact from "../../assets/images/verified-contact.svg"
import importExportIcon from "../../assets/images/import-export.svg"
import restrictedDatesIcon from "../../assets/images/restrictedDates.svg"

import dripIcon from "../../assets/images/drip-icon.svg"
import { useSelector } from "react-redux"
import tag from "../../assets/images/tag.svg"
import RestrictedContact from "../../assets/images/restrictedContact.svg"

const SidebarContent2 = props => {
  const ref = useRef()
  const [tUnreadMsg, setTUnreadMsg] = useState(0)

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  async function ongGetListUnreadMessages() {
    let params = {
      page: 1,
      limit: 20,
      currentstatus: "Unread",
    }
    try {
      const resp = await get(
        process.env.REACT_APP_PORT + "/api/notification/list",
        { params }
      )
      if (resp && Array.isArray(resp?.data?.docs)) {
        setTUnreadMsg(resp.data.totalDocs)
      }
    } catch (error) {
      console.error(error)
    }
  }
  let message_popup = useSelector(state => state.Campaign?.successMsg)
  let message_ = useSelector(state => state.Prospects?.successMsg)
  // useEffect(() => {
  //   // ongGetListUnreadMessages()
  // }, [message_popup, message_])

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/*<li className="menu-title">{props.t("Main")} </li>*/}
            <li>
              <Link to="/dashboard2" className="waves-effect">
                <img src={dashboardIcon} alt="icon" />
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/campaigns" className="waves-effect">
                <img src={campaignIcon} alt="icon" />
                <span>{props.t("Campaigns")}</span>
              </Link>
            </li>
            <li>
              <Link to="/prospects" className="waves-effect">
                <img src={prospectIcon} alt="icon" />
                <span>{props.t("Prospects")}</span>
              </Link>
            </li>
            <li>
              <Link to="/numbers" className="waves-effect">
                <img src={numbersIcon} alt="icon" />
                <span>{props.t("Numbers")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <img src={templatesIcon} alt="icon" />
                <span>{props.t("Templates")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/templates">{props.t("Templates")}</Link>
                </li>
                <li>
                  <Link to="/categories">{props.t("Categories")} </Link>
                </li>
                <li>
                  <Link to="/quick-replies">{props.t("Quick Replies")} </Link>
                </li>
                <li>
                  <Link to="/templates-setting">
                    {props.t("Template Settings")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <img src={notificationIcon} alt="icon" />
                <span>{props.t("Notifications")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/notifications">{props.t("Notifications")}</Link>
                </li>
                <li>
                  <Link to="/reminders">{props.t("Reminders")} </Link>
                </li>
                <li>
                  <Link to="/notes">{props.t("Notes")} </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <img src={settingicon} alt="icon" />
                <span>{props.t("Account Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {/* <li><Link to="/dnc-list">{props.t("DNC List")}</Link></li> */}
                <li>
                  <Link to="/optouts">{props.t("Optout")}</Link>
                </li>
                <li>
                  <Link to="/ptcrm">{props.t("Config")}</Link>
                </li>
                <li>
                  <Link to="/lead-stages">{props.t("Lead Stages")}</Link>
                </li>
                <li>
                  <Link to="/aiconfig">{props.t("AI Config")}</Link>
                </li>
                {/* <li><Link to="/markets">{props.t("Markets")}</Link></li> */}
                {/* <li><Link to="/marketConfig">{props.t("Market Config")}</Link></li> */}
                {/* <li><Link to="/management-phone-number">
                    {props.t("Mobile Alert")}
                  </Link></li> */}
                <li>
                  <Link to="/skipRules">{props.t("Skip Rules")}</Link>
                </li>
              </ul>
            </li>
            <li></li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <img src={marketConfigIcon} alt="icon" />
                <span>{props.t("Market")}</span>
              </Link>

              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/markets">{props.t("Markets")}</Link>
                </li>
                <li>
                  <Link to="/marketConfig">{props.t("Market Config")}</Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/users" className="waves-effect">
                <img src={usericon} alt="icon" />
                <span>{props.t("Users")}</span>
              </Link>
            </li> */}
            {/* <li>
              <Link to="/message-unread" className="waves-effect">
                <img src={allUnreadIcon} alt="icon" />
                <span>
                  {props.t("All Unread ")}({tUnreadMsg || 0})
                </span>
                {tUnreadMsg && tUnreadMsg > 0 ? (
                  <span className="msg-count">{tUnreadMsg}</span>
                ) : null}
              </Link>
            </li> */}
            <li>
              <Link to="/contact-cart" className="waves-effect">
                <img src={verifiedContact} alt="icon" />
                <span>{props.t("Verified Contacts")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <img src={RestrictedContact} alt="icon" />
                <span>{props.t("Restricted Contacts")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/restrictedContact/dnc-list">
                    {props.t("DNC List")}
                  </Link>
                </li>
                <li>
                  <Link to="/restrictedContact/wrongNumber">
                    {props.t("Wrong Number")}
                  </Link>
                </li>
                <li>
                  <Link to="/restrictedContact/litigator">
                    {props.t("Litigator")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/drip-automation" className="waves-effect">
                <img src={dripIcon} alt="icon" />
                <span>{props.t("Drip Automations")}</span>
              </Link>
            </li>
            <li>
              <Link to="/tags" className="waves-effect">
                <img src={tag} alt="icon" />
                <span>{props.t("Tags Management")}</span>
              </Link>
            </li>
            <li>
              <Link to="/import-export" className="waves-effect">
                <img src={importExportIcon} alt="icon" />
                <span>{props.t("Import / Export")}</span>
              </Link>
            </li>
            <li>
              <Link to="/restricted-dates" className="waves-effect">
                <img src={restrictedDatesIcon} alt="icon" />
                <span>{props.t("Restricted Dates")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent2.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent2))
