export const TemplatePrompts = [
  {
    key: "templateOptimization",
    title: "For optimizing existing templates",
    prompts: [
      "Create a template update to make messages more engaging and increase delivery rates",
      "Write a message refining language to improve recipient response for seller outreach",
      "Draft a template using data-driven insights to increase message relevance for buyers",
      "Create a template revision that includes personalization tips for recipient engagement",
      "Write a message adapting to current property market trends to make outreach more effective",
      "Create a template modification to improve open rates by emphasizing subject line adjustments",
    ],
  },
  {
    key: "sellerProspecting",
    title: "For reaching out to potential sellers",
    prompts: [
      "Create a template asking if homeowner is interested in selling based on current high market value",
      "Write a message mentioning recent sales in their neighborhood and asking if they'd consider selling",
      "Draft a template about an interested buyer looking for properties in their specific area",
      "Create a template offering a free property valuation to gauge selling interest",
      "Write a message highlighting market demand in their area and suggesting a selling opportunity",
      "Create a template mentioning sold properties above asking price in their neighborhood",
    ],
  },
  {
    key: "buyerProspecting",
    title: "For potential buyers",
    prompts: [
      "Create a template introducing new listings matching their previous search criteria",
      "Write a message about exclusive/pre-market properties becoming available soon",
      "Draft a template offering to set up property alerts for their preferred areas",
      "Create a template about special financing options available for first-time buyers",
      "Write a message highlighting price reductions on properties they might be interested in",
      "Create a template offering comparative market analysis for properties they're interested in",
    ],
  },
  {
    key: "followUps",
    title: "For follow-ups",
    prompts: [
      "Create a template following up after property viewing",
      "Write a message checking in after sending property information",
      "Draft a template following up on their property valuation request",
      "Create a template checking if they're still interested in selling",
      "Write a follow-up message after open house attendance",
      "Create a template following up on their mortgage pre-approval process",
    ],
  },
  {
    key: "marketUpdates",
    title: "For market updates",
    prompts: [
      "Create a template sharing latest market trends in their area",
      "Write a message about recent changes in property values in their neighborhood",
      "Draft a template announcing new development projects affecting property values",
      "Create a template sharing seasonal market opportunities",
      "Write a message about current mortgage rate trends and opportunities",
      "Create a template about upcoming real estate market changes",
    ],
  },
  {
    key: "leadNurturing",
    title: "For nurturing leads",
    prompts: [
      "Create a template providing helpful home maintenance tips",
      "Write a message sharing real estate investment insights",
      "Draft a template offering seasonal real estate advice",
      "Create a template sharing local community updates and events",
      "Write a message providing real estate market education",
      "Create a template offering property investment strategies",
    ],
  },
  {
    key: "timingSensitive",
    title: "For special occasions/timing",
    prompts: [
      "Create a template for end-of-year real estate opportunities",
      "Write a message about spring selling season preparation",
      "Draft a template for holiday season buying opportunities",
      "Create a template about tax season real estate considerations",
      "Write a message about summer market activity",
      "Create a template for new year real estate planning",
    ],
  },
]
